/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

html,
body {
  height: 100%;
  background: #f2f2f2;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.inner-content {
  padding: 24px;
  margin: 0 24px;
  background: #fff;
  min-height: 360px;
  border-radius: 10px;
}

.mr-5 {
  margin-right: 5px;
}
